import HeroSection from "./hero";
import DescriptionSection from "./description";
import { Feedback } from "./feedback";
import { ChatBtn } from "../chat-btn";
export function JobsPage() {
  return (
    <main class="w-screen relative">
      <HeroSection title={"Jobs"} />
      <DescriptionSection />
      <Feedback />
      <ChatBtn />
    </main>
  );
}
